.o-user-content {
    --gap: var(--padding-normal);
    --link-decoration: none;
    
    width: 100%;

    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 0.25em;
    }

    h4 {
        font-size: var(--font-size-text);
        font-weight: var(--font-weight-bold);
    }

    h1 {
        margin-bottom: 0.55em;
    }

    p {
        margin-bottom: var(--gap);

        &:last-child {
            margin-bottom: 0;
        }
    }

    p + h2 {
        margin-top: 1em;
    }

    ul {
        padding-inline-start: 2em;
        list-style: disc;
        margin-bottom: var(--gap);
    }

    // a {
    //     img {
    //         display: block;
    //     }
    // }

    table {
    }

    img {
        display: inline-block;
    }
}
