.c-column-block {
    display: grid;
    grid-auto-flow: row;
    gap: 2rem;

    &--single {
        grid-template-rows: 1fr;
    }

    &--dual {
        grid-template-rows: repeat(2, auto);

        // the dual-column block is based on a 12 column grid
        @screen sm {
            grid-template-rows: auto;
            grid-template-columns: repeat(12, 1fr);

            .c-column-block__item {
                grid-column: 1 / span 6;

                & + .c-column-block__item {
                    grid-column: 7 / span 6;
                }
            }
        }
    }
    
    // these are the classes for the divisioning of the columns
    @for $i from 1 through 12 {
        &--dual-#{$i} {
            @screen sm {
                .c-column-block__item {
                    grid-column: 1 / span #{$i};

                    & + .c-column-block__item {
                        grid-column: #{$i + 1} / span #{12 - $i};
                    }
                }
            }
        }
    }

    &--triple {
        grid-template-rows: repeat(3, auto);

        @screen sm {
            grid-template-rows: auto;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &--quad {
        grid-template-rows: repeat(4, auto);

        @screen sm {
            grid-template-rows: auto;
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__item {
        --justify: start;
        --block-padding: 0;

        display: flex;
        flex-direction: column;
        justify-content: var(--justify);
        gap: 2rem;

        &--top {
            --justify: start;
        }

        &--center {
            --justify: center;
        }

        &--bottom {
            --justify: flex-end;
        }

        .o-section {
            width: 100%;
        }
    }
}
