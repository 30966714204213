.o-depth-1 {
    box-shadow: 0px 1px 0px 0 rgba(var(--color-black-rgb), 0.15);
}
.o-depth-2 {
    box-shadow: 0px 2px 10px 0 rgba(var(--color-black-rgb), 0.20);
}
.o-depth-3 {
    box-shadow: 0px 3px 28px 0 rgba(var(--color-black-rgb), 0.25);
}
.o-depth-4 {
    box-shadow: 0px 9px 30px 0 rgba(var(--color-black-rgb), 0.30);
}
.o-depth-5 {
    box-shadow: 0px 15px 45px 0 rgba(var(--color-black-rgb), 0.35);
}
