.c-quick-nav {
    --background-color: var(--color-black);
    background-color: var(--background-color);
    --link-color: rgba(var(--color-white-rgb), 0.8);
    font-size: 1.2rem;
    font-weight: var(--font-weight-normal);
    text-transform: uppercase;
    display: none;
    padding-inline: var(--column-padding-normal);

    @screen sm {
        display: flex;
        height: var(--quick-nav-height);
    }

    > ul {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: flex-end;

        > li {
            
            position: relative;
        }
        > li + li {
            border-left: 1px dashed rgba(var(--color-white-rgb), 0.5);
            padding-left: 1rem;
        }
    }

    // li:last-of-type{
    //     padding-left: 2em;
    // }

    a,
    span {
        line-height: normal;
    }
    a {
        display: block;
        transform: translateY(0);
        cursor: pointer;

        i {
            margin-right: 0.5em;
            vertical-align: middle;
        }

        &:hover {
            --link-color: var(--accent-color);
        }
        &:active {
            transform: translateY(1px);
        }
    }

    &__sub {
        display: block;
        font-size: 2.5rem;

        > ul {
            justify-content: space-between;
            padding-block: 1rem;
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 1fr;
            min-height: 100px;

            li,
            a {
                height: 100%;
            }
        }

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-block: 1rem;
            i {
                margin-right: 0;
                margin-bottom: 1rem;
            }
            span {
                font-size: var(--font-size-footer);
                line-height: var(--line-height-tighter);
            }
        }

        .c-language-selector__list {
            top: auto;
            bottom: 100%;
            font-size: var(--font-size-footer);
            right: calc(-1 * var(--column-padding-normal));
        }
    }

    &__login {
         display: block;
         position: absolute;
         right: -1em;
         top: 2em;
         pointer-events: none;
         will-change: opacity;
         overflow: hidden;
         transition: max-height 0.1s ease-in-out;
         z-index: 1;
         max-height: 0;
         background-color: var(--background-color);
         border-radius: var(--border-radius);
         border-top-left-radius: 0;
         border-top-right-radius: 0;
         padding-left: 2rem;

        &.open {
            pointer-events: all;
            max-height: 100px;
        }

        li {
            padding: 1rem 2rem;
            text-align: right;
            white-space: nowrap;
        }
    }
}
