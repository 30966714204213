header {
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    transition: top 0.2s ease-in-out;

    &.nav-up {
        top: calc(var(--nav-height) * -1);
    }
}
