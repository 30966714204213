.c-stars {
    --star-size: 4rem;
    --star-color: var(--color-grey);
    --star-background: var(--color-blue);
    --percent: calc(var(--rating) / 5 * 100%);

    display: inline-block;
    font-size: var(--star-size);
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;

    &::before {
        content: "★★★★★";
        letter-spacing: 3px;
        background: linear-gradient(
            90deg,
            var(--star-background) var(--percent),
            var(--star-color) var(--percent)
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &-5 {
        --rating: 5;
    }
    &-4 {
        --rating: 4;
    }
    &-3 {
        --rating: 3;
    }
    &-2 {
        --rating: 2;
    }
    &-1 {
        --rating: 1;
    }
}
