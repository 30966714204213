.o-flavor {
    &--rounded {
        --border-radius: 8px;
    }

    &--fullscreen{
        min-height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--gnap {
        --background-color: var(--color-blue-dark);
        --title-color: var(--color-white);
        --text-color: var(--color-white);
         --link-color:var(--color-yellow);
         --link-hover-color: var(--color-yellow);
    }

    &--gnap-light {
        --background-color: var(--color-blue);
        --title-color: var(--color-white);
        --text-color: var(--color-white);
        --link-color:var(--color-yellow);
        --link-hover-color: var(--color-yellow);
    }
    &--gnap-gradient {
        --background-color: linear-gradient(180deg, var(--color-blue) 0%, var(--color-blue-darker) 100%);
        --title-color: var(--color-white);
        --text-color: var(--color-white);
        --link-color:var(--color-yellow);
        --link-hover-color: var(--color-yellow);
    }

    &--kwieb {        
        --background-color: var(--color-pink-dark);
        --title-color: var(--color-white);
        --text-color: var(--color-white);
        --link-color:var(--color-yellow);
        --link-hover-color: var(--color-yellow);
    }
    &--kwieb-light {        
        --background-color: var(--color-pink);
        --title-color: var(--color-white);
        --text-color: var(--color-white);
        --link-color:var(--color-yellow);
        --link-hover-color: var(--color-yellow);
    }
    &--kwieb-gradient {        
        --background-color: linear-gradient(180deg, var(--color-pink-dark) 0%, var(--color-pink-darkest) 100%);
        --title-color: var(--color-white);
        --text-color: var(--color-white);
        --link-color:var(--color-yellow);
        --link-hover-color: var(--color-yellow);
    }

    &--dark {
        --background-color: var(--color-black);
        --title-color: var(--color-white);
        --text-color: var(--color-white);
        --link-color:var(--color-yellow);
        --link-hover-color: var(--color-yellow);
    }

    &--light {
        --background-color: var(--color-grey-light);
    }
    &--normal {
        --background-color: var(--color-transparent);
    }
    &--white {
        --background-color: var(--color-white);
    }
}