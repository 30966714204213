.c-language-selector {
    --background-color: var(--color-black);
    --text-color: var(--accent-color);
    position: relative;
    line-height: normal;
    cursor: pointer;

    &__list {
        overflow: hidden;
        display: block;
        user-select: none;
        pointer-events: none;
        opacity: 1;
        max-height: 0;
        transition: max-height 0.1s ease-in-out;
        //@apply font-navigation text-xl text-white;
        border-radius: var(--border-radius);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: rgba(var(--color-black-rgb), 0.1);
        color: var(--color-black);
        z-index: 1;
        position: absolute;
        right: -1em;
        top: 2em;
        background-color: var(--background-color);

        &.open {
            pointer-events: all;
            max-height: 100px;
        }
        &-item {
            padding: 1em;
            padding-left: 3rem;
            text-align: right;
            white-space: nowrap;
        }
    }
}
