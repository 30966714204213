.o-user-content {
    a {
        position: relative;
    }

    a:not(.image-link,.cta-link)::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        border-radius: 1px;
        background-color: currentColor;
        bottom: 0;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform 0.2s ease-in-out;
    }

    a:hover::before {
        transform-origin: left;
        transform: scaleX(1);
    }
}
