$movement: 200px;

@mixin effect {
    transition-property: opacity, transform;
    transition-duration: 0.33s;
    transition-timing-function: ease-out;
}

.fx-slide-up {
    @include effect;
    transform: translateY($movement);

    &.in-view {
        transform: translateY(0px);
    }
}

.fx-slide-left {
    @include effect;
    transform: translateX($movement);

    &.in-view {
        transform: translateY(0px);
    }
}

.fx-slide-right {
    @include effect;
    transform: translateX(($movement * -1));

    &.in-view {
        transform: translateY(0px);
    }
}
.fx-fade {
    @include effect;
    opacity: 0;

    &.in-view {
        opacity: 1;
    }
}
