html {
    font-size: 62.5%;
    text-size-adjust: 100%;
}
body {
    font-weight: var(--font-weight-normal);
    color: var(--text-color);
    font-size: var(--font-size-info);
    background-color: var(--color-white);
    font-family: var(--font-family);
}

nav {
    font-family: var(--font-family);
}

h1,
h2 {
    color: var(--title-color);
}
h3,
h4,
h5,
h6 {
    color: var(--text-color);
    --link-color: var(--text-color);
    --link-hover-color: var(--text-color);
}

h1,
.o-h1-style {
    //@apply text-3xl font-heading text-blue font-bold mb-6;
    font: var(--h1-font);
    color: var(--title-color);
}

h2 {
    font: var(--h2-font);
    margin-bottom: 0.25rem;
}
h3 {
    font: var(--h3-font);
}
h4 {
    font: var(--h4-font);
}

b,
strong {
    font-weight: var(--font-weight-semibold);
}

a {
    color: var(--link-color);
    text-decoration: var(--link-decoration);
    transition-property: opacity, color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    &:hover {
        color: var(--link-hover-color);
        opacity: var(--link-hover-opacity);
        text-decoration: var(--link-decoration);
    }
}

main {
    font-size: var(--font-size-text);
    overflow-x: hidden;
}
p,
li {
    line-height: var(--line-height-loose);

    // @screen sm {
    //     line-height: var(--line-height-loose);
    // }
}

q {
    font-size: var(--font-size-footer);
    font-weight: var(--font-weight-normal);
    font-family: var(--font-family-small);
}