table {
    border-collapse: collapse;
    --border-color: rgba(0, 0, 0, 0.1);

    td,
    th {
        padding: 0.5rem;

        @screen sm {
            padding: 1.25rem;
        }
    }

    &.o-table {
        --font-size: 3vw;
        font-size: var(--font-size);
        border-collapse: collapse;
        border-style: 1px solid transparent;

        @screen sm {
            --font-size: var(--font-size-info);
        }

        td,
        th {
            border-bottom: 1px solid var(--border-color);
        }

        // tr:first-child {
        //     th, td {
        //         border-top: none;
        //     }
        // }

        // tr:last-child {
        //     th, td {
        //         border-bottom: none;
        //     }
        // }

        // tr {
        //     th:first-child,
        //     td:first-child {
        //         border-left: none;
        //     }
        //     th:last-child,
        //     td:last-child {
        //         border-right: none;
        //     }
        // }
    }

    &.o-table--striped {
        @extend .o-table;

        tr:nth-of-type(even) {
            background-color: rgba(0, 0, 0, 0.025);
        }
    }
}
