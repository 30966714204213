body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin: 0;
}
main, footer  {
    display: flex;
    flex-direction: column;
    flex: 1;
}
