body {
    // sizes
    --container-padding: 1rem;
    --layout-width: 960px;
    // colors
    --color-transparent: rgba(0, 0, 0, 0);
    --color-white-rgb: 255, 255, 255;
    --color-black-rgb: 22, 22, 22;
    --color-white: rgba(var(--color-white-rgb), 1);
    --color-black: rgba(var(--color-black-rgb), 1);
    --color-true-black: rgba(0, 0, 0, 1);
    --color-blue: #007ebc;
    --color-blue-dark: #1d76a1;
    --color-blue-darker: #005D8A;
    --color-blue-darkest: #004263;
    --color-grey-dark: rgba(0, 0, 0, 0.6);
    --color-grey-darker: rgba(0, 0, 0, 0.3);
    --color-grey: #eaeaea;
    --color-grey-light: #f2f2f2;
    --color-grey-lightest: RGB(247, 247, 247);
    --color-orange: hsl(20, 100%, 50%);
    --color-orange-darker: hsl(20, 100%, 45%);
    --color-yellow: #ffce10;
    --color-pink: #e4017a;
    --color-pink-dark: #b9276b;
    --color-pink-darkest: #8D0043;
    --color-green: #6cb622;
    --color-danger: var(--color-orange);
    // color types
    --text-color: var(--color-black);
    --title-color: var(--color-blue);
    --accent-color: var(--color-blue);
    --link-color: var(--color-blue);
    --link-hover-color: var(--link-color);
    --link-hover-opacity: 1;
    --background: var(--color-transparent);
    --background-color: var(--color-transparent);
    --contrasting-background-color: var(--color-grey-light);
    // Hero colors
    --hero-title-color: var(--color-white);
    --hero-background-color: var(--color-transparent);
    // Card colors
    --card-background-color: var(--color-white);
    --card-text-color: var(--color-black);
    --card-padding: 2rem;
    --card-border-radius: var(--card-padding);
    // Form colors
    --form-background-color: var(--contrasting-background-color);
    --form-border-color: var(--contrasting-background-color);
    --form-color: var(--color-black);
    --form-placeholder-color: var(--form-color);
    --form-border-color-active: var(--accent-color);
    --form-font-family: var(--font-family);
    --form-placeholder-font-family: var(--font-family);
    --form-placeholder-color: var(--color-grey-darker);
    // font families
    --font-family: "Poppins", sans-serif;
    --app-font-family: "Open Sans", sans-serif;
    --font-family-small: "Source Sans Pro", sans-serif;
    // font weights
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;

    // font sizes
    --font-size-h1: min(5rem, 7vw);
    --font-size-h2: min(3.2rem, 5vw);
    --font-size-h3: 1.9rem;
    --font-size-h4: min(4.6rem, 6vw);
    --font-size-text-large: 1.9rem;
    --font-size-text: 1.7rem;
    --font-size-info: 1.5rem;
    --font-size-footer: 1.3rem;
    --font-size-small: 1.1rem;
    --font-size-body: var(--font-size-text);
    // line heights
    --line-height-tight: 1.326;
    --line-height-tighter: 1.2;
    --line-height-normal: 1.474;
    --line-height-loose: 1.765;
    // fonts
    --navigation-font: var(--font-weight-medium) 17px /
        var(--line-height-normal) var(--font-family);
    --subnavigation-font: var(--font-weight-normal) 17px /
        var(--line-height-normal) var(--font-family);
    --h1-font: var(--font-weight-bold) var(--font-size-h1) /
        var(--line-height-tighter) var(--font-family);
    --h2-font: var(--font-weight-bold) var(--font-size-h2) /
        var(--line-height-tight) var(--font-family);
    --h3-font: var(--font-weight-semibold) var(--font-size-h3) /
        var(--line-height-normal) var(--font-family);
    --h4-font: var(--font-weight-bold) var(--font-size-h4) /
        var(--line-height-tight) var(--font-family);
    --text-font: var(--font-weight-normal) 1.5rem / var(--line-height-normal)
        var(--font-family);
    --copy-title-font: var(--font-weight-bold) var(--font-size-h2) /
        var(--line-height-tight) var(--font-family);
    --copy-callout-font: var(--font-weight-semibold) var(--font-size-text-large) /
        var(--line-height-normal) var(--font-family);
    --body-font: var(--font-weight-normal) 1.7rem / var(--line-height-normal)
        var(--font-family);
    --hero-title-font: var(--font-weight-semibold) 4.3rem /
        var(--line-height-tight) var(--font-family);

    // paddings
    --padding-normal: 1rem;
    --column-padding-normal: 2rem;
    --block-padding: 1rem;
    --layout-padding: 3rem;
    --layout-padding-top: 1rem;
    --layout-padding-bottom: 1rem;
    --form-padding: 1rem;


    @screen sm {
        --layout-padding: 5rem;
        --card-padding: 2.5rem;
        --main-nav-height: 80px;
        --quick-nav-height: 35px;
        --breadcrumb-height: 35px;
    }

    @screen xs-only {
        --column-padding-normal: 1rem;
    }

    @screen lg {
        --column-padding-normal: 0rem;
    }
    // misc
    --link-decoration: none;

    --border-radius: 6px;
    --main-nav-height: 60px;
    --quick-nav-height: 0;
    --breadcrumb-height: 0;
    --nav-height: calc(var(--main-nav-height) + var(--quick-nav-height));
    --full-nav-height: calc(var(--main-nav-height) + var(--quick-nav-height) + var(--breadcrumb-height));

    // layout
    --full-height: calc(80vh - var(--nav-height, 0));
    
    // swiper values
    --swiper-theme-color: var(--accent-color);
    --swiper-pagination-color: var(--color-white);
    --swiper-pagination-bullet-inactive-opacity: 0.3;
    --swiper-navigation-size: 30px;
    --swiper-navigation-color: rgba(255,255,255,1);
    --swiper-pagination-bullet-size: 10px;

    // Other variables
    --section-background-overflow: visible;
}
