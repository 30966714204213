.c-tags {
    --size: var(--font-size-info);
    --background-color: var(--color-blue);
    --color: var(--color-white);
    --active-color: var(--color-pink);
    --link-color: var(--color);
    --link-hover-color: var(--color);
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-block-end: 1rem;

    &--small {
        --size: 1.1rem;
    }

    &__item {
        background-color: var(--background-color);
        color: var(--color);
        padding: 0.5em 1em;
        border-radius: 6px;
        font-size: var(--size);

        &--selected {
            opacity: .5;
        }
    }
}
