.c-hero-block {
    --background: transparent;
    --color: var(--color-white);
    --title-color: var(--color);
    --text-color: var(--color);
    --block-padding: 9.5rem;
    position: relative;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8rem;
    
    @screen sm {
        flex-direction: row;
    }

    &__content {
        position: relative;
        z-index: 1;
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: min-content;

        .o-copy__callout {
        }

        .c-btn {
            margin-top: 3rem;
            align-self: flex-end;
            justify-self: flex-start;
        }
    }

    &__title {
        font-weight: var(--font-weight-semibold);
    }
    
    &__subtitle {
        margin: 0;
    }

    &__image {
        align-self: center;
        
        @screen sm {
            max-width: 225px;
        }
    }


}
