.o-copy {
    // Copy Colors
    --copy-title-color: var(--title-color);
    --copy-text-color: var(--text-color);
    
    color: var(--copy-text-color);

    &__body {
        font: var(--body-font);
        // font-size: var(--font-size-text-large);
    }

    strong {
        font-weight: var(--font-weight-semibold);
    }

    &__body {
        margin-bottom: 2rem;
    }

    &__title {
        font: var(--copy-title-font);
        color: var(--copy-title-color);
        margin-bottom: .75em;
    }

    &__call-out {
        font: var(--copy-callout-font);
        margin-top: 1em;
        margin-bottom: .5em;
    }
}
