:root {
    --site-menu-width: 100px;
    --site-menu-height: 100px;
    --site-menu-offset: 50px;
    --site-menu-left: 50%;
    --site-menu-translate-x: -50%;
    --site-menu-rotate: 0deg;
}
.c-site-menu {
    display: none;
    --link-color: var(--menu-link-color);
    --link-hover-color: var(--menu-text-color);
    --text-color: var(--menu-text-color);
    // onlye display on tablet and beyond
    @screen md {
        display: inline-block;
    }
    position: absolute;
    top: 0;
    left: var(--site-menu-left);
    width: var(--site-menu-width);
    height: var(--site-menu-height);
    box-sizing: content-box;
    opacity: 1;
    transform: translateX(
            calc(var(--site-menu-translate-x) - var(--site-menu-offset))
        )
        rotateX(var(--site-menu-rotate));
    transform-origin: 50% -50px;
    transition-property: transform, width, height;
    transition: 250ms;
    will-change: transform, width, height;

    &--hidden {
        pointer-events: none;
        opacity: 0;
        --site-menu-rotate: -15deg;
    }

    &__indicator {
        left: var(--site-menu-offset);
        top: 1px;
        position: absolute;
        width: 0;
        height: 0;
        transform: translateX(-50%);
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid white;
    }

    &__section-wrapper {
        --border-radius: 17px;
        pointer-events: all;
        position: absolute;
        display: inline-block;
        left: 50%;
        top: 12px;
        opacity: 1;
        z-index: 2;
        transform: translateX(-50%);
        transition: 250ms;
        transition-property: opacity, transform;
        will-change: opacity, transform;

        --menu-background-color: #f7f7f7;
        --menu-text-color: #000;

        &--hidden {
            pointer-events: none;
            opacity: 0;
        }
    }

    &__section {
        position: relative;
        display: inline-block;
        padding: 5px;
        background-color: #fff;
        border-radius: var(--border-radius);
        box-shadow: 5px 20px 50px rgba(0, 0, 0, 0.158408);
    }

    &__item {
        --radius: 12px;
        --text-color: var(--menu-text-color);
        --link-color: var(--menu-text-color);
        display: block;

        &:first-child .c-site-menu__item-body {
            border-top-left-radius: var(--radius);
            border-top-right-radius: var(--radius);
        }
        &:last-child .c-site-menu__item-body {
            border-bottom-left-radius: var(--radius);
            border-bottom-right-radius: var(--radius);
        }

        &:hover,
        &.open {
            --menu-background-color: var(--main-theme-color);
            --menu-text-color: var(--secondary-theme-color);
        }
        position: relative;

        & + .c-site-menu__item {
            margin-top: 3px;
        }

        &-body {
            background-color: var(--menu-background-color);
            color: var(--menu-text-color);
            padding: 20px;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: 1fr 1fr;
            align-items: center;
            column-gap: 10px;
            transition-property: background-color;
            transition: 250ms;
        }
        &-title {
            font-size: var(--font-size-text);
            white-space: nowrap;
            grid-row: 1;
            grid-column: 1;
        }
        &-payoff {
            white-space: nowrap;
            font-size: var(--font-size-footer);
            grid-row: 2;
            grid-column: 1;
        }

        .icon-arrow {
            grid-column: 2;
            grid-row-start: 1;
            grid-row-end: 3;
        }
    }

    &__sub-nav-item {
        --menu-background-color: var(--main-theme-color);
        --menu-text-color: var(--secondary-theme-color);
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.295946);
        border-radius: var(--border-radius);
        white-space: nowrap;
        display: inline-block;
        position: absolute;
        left: 100%;
        top: 5px;
        background-color: var(--menu-background-color);
        z-index: -1;
        padding: 20px;
        padding-left: 25px;
        pointer-events: all;
        opacity: 1;
        transform: translateX(-10px) translateY(20px);
        transition: 250ms;
        transition-property: opacity, transform;
        will-change: opacity, transform;
        color: var(--menu-text-color);

        a,
        h3,
        h4,
        h5,
        h6 {
            color: var(--menu-text-color);
        }

        &--hidden {
            pointer-events: none;
            opacity: 0;
            transform: translateX(-100%) translateY(20px);
        }
    }
}

.c-mobile-site-menu {
    --link-color: var(--menu-text-color);
    --link-hover-color: var(--menu-text-color);
    --text-color: var(--menu-text-color);
    display: inline-block;
    @screen md {
        display: none;
    }
    position: fixed;
    top: 0;
    width: 100%;
    height: auto;
    max-height: 100vh;
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
    will-change: opacity, transform;
    transition: 250ms;
    left: 0;
    background-color: var(--color-white);
    z-index: 1;
    min-height: 600px;
    pointer-events: all;

    &--hidden {
        opacity: 0;
        transform: translateY(-100%);
    }

    &__top {
        --link-color: var(--secondary-theme-color);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        user-select: none;
        color: var(--color-grey-darker);

        .logo {
            display: block;
            min-width: 130px;
            max-width: 200px;
            height: var(--main-nav-height);
            max-height: 30px;
            align-self: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../images/ziber-logo.png");
        }

        a,
        i {
            cursor: pointer;
            color: inherit;
            vertical-align: middle;
        }
    }

    &__sections {
        padding: 10px;
        position: relative;
    }
    &__section-wrapper {
        margin-bottom: 2px;

        .btn {
            background-color: var(--color-orange);
            color: var(--color-white);
        }
    }

    &__section {
        padding: 18px;
        background-color: RGB(247, 247, 247);
        cursor: pointer;
        display: block;
    }

    &__item {
        font-weight: var(--font-weight-semibold);
        font-size: 1.9rem;
        display: block;
    }

    .c-site-menu__item {
        cursor: pointer;
    }

    .icon-arrow--flipped {
        margin-right: 10px;
        transform: scaleX(-1);
        display: inline-block;
    }


    .language-selection {
        --text-color: var(--color-blue);
        --link-color: var(--color-blue);
        --secondary-theme-color: white;

        .c-mobile-site-menu__item {
            // color: var(--color-blue);
            font-weight: var(--font-weight-medium);
            font-size: 1.5rem;
        }
        .c-mobile-site-menu__top {
            color: white;
            border-bottom: 1px solid #A0A0A0;
        }
        .c-mobile-site-sub-menu {
            color: white;
            --text-color: white;
            --link-color: white;
            background-color: #444444;
        }
        ul {
            padding: 10px;

            li {
                line-height: 3em;
                &.selected {
                    font-weight: var(--font-weight-bold);
                }
            }
        }
    }
}

.c-mobile-site-sub-menu {
    display: inline-block;
    @screen md {
        display: none;
    }
    position: fixed;
    top: 0;
    width: 100%;
    height: auto;
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
    will-change: opacity, transform;
    transition: 250ms;
    left: 0;
    background-color: var(--color-white);
    z-index: 2;
    min-height: 600px;

    &--hidden {
        opacity: 0;
        pointer-events: none;
        transform: translateX(-100%);
    }

    > .c-mobile-site-menu__sections > .c-site-menu__item {
        --menu-background-color: var(--color-grey-lightest);
        --menu-text-color: var(--main-theme-color);
        .c-site-menu__item-title {
            font-weight: var(--font-weight-bold);
            font-size: 1.9rem;
        }
        .c-site-menu__item-body {
            flex: 1;
        }
        .c-site-menu__item-payoff {
            white-space: normal;
        }
    }
}
.c-mobile-site-sub-sub-menu {
    --menu-background-color: var(--main-theme-color);
    --menu-text-color: var(--secondary-theme-color);

    display: inline-flex;
    flex-direction: column;
    @screen md {
        display: none;
    }
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 100%;
    max-height: 100vh;
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
    will-change: opacity, transform;
    transition: 250ms;
    left: 0;
    background-color: var(--menu-background-color);
    z-index: 3;

    &--hidden {
        opacity: 0;
        pointer-events: none;
        transform: translateX(-100%);
    }

    .c-mobile-site-menu__top {
        border-bottom: 1px solid var(--menu-text-color);
        color: var(--menu-text-color);
    }
    .c-site-menu__item {
        flex-grow: 1;
        overflow: scroll;
    }

    .c-site-menu__item-body {
        display: inline-block;
        padding: 20px;

        h6 {
            font-weight: var(--font-weight-semibold);
            margin-bottom: .5em;
        }
        p,
        a,
        h6 {
            line-height: 3em;
        }
    }
}

.c-site-menu-container {
    position: absolute;
    width: 100%;
    left: 0;
    height: 1000px;

    pointer-events: none;

    // [class*="o-theme"] {
    //     --link-color: var(--menu-text-color);
    //     --link-hover-color: var(--menu-text-color);
    //     --text-color: var(--menu-text-color);
    // }

    // &--visible {
    //     pointer-events: all;
    // }
}
