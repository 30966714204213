.c-clients-block {
    display: flex;
    flex-wrap: wrap;
    gap: 6rem;

    figure {
        aspect-ratio: 1;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 34%; // bigger than a thrid, so it can grow
        max-width: 50%;

        @screen md {
            flex-basis: 26%; // bigger than a fourth, so it can grow
            max-width: 33%;
        }

        @screen lg {
            flex-basis: 20%; // bigger than a fitfh, so it can grow
            max-width: 25%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
