.c-slider {
    --padding: 2rem;
    display: flex;
    gap: 3rem;
    flex-direction: row;
    flex-wrap: wrap;
    font: var(--h3-font);
    justify-content: center;

    &__item {
        display: flex;
        flex-direction: column;
        position: relative;
        aspect-ratio: 300/270;
        overflow: hidden;
        border-radius: var(--padding);
        flex-shrink: 0;
        flex-basis: 100%;

        @screen sm {
            flex-basis: 30%;
        }

        figure {
            inset: 0;
            width: 100%;
            height: 100%;

            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }

        &-content {
            /* transform: translateY(-1rem); */
            position: absolute;
            left: var(--padding);
            right: var(--padding);
            bottom: var(--padding);
            height: auto;
            text-align: center;
            color: var(--color-white);
            border-radius: calc(1em + var(--padding));
            border-width: 1px;
            border-style: solid;
            border-color: var(--color-white);
            padding: 1rem 0.5rem;
            background-color: rgba(0, 0, 0, 0.2);
        }

        &:hover p {
            background-color: var(--color-white);
            color: var(--color-blue);
        }
    }
}
