.o-stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    * {
        margin-top: 0;
        margin-bottom: 0;
    }
    > * + * {
        margin-top: var(--space, 1rem);
    }

    &--sm {
        --space: 0.5rem;
    }

    &--no-gutter {
        --space: 0rem;
    }
}
