@use "sass:math";
@mixin aspect($width, $height) {
    aspect-ratio: math.div($width, $height);
    width: 100%;
    height: auto;
    min-height: unset;
}

.c-hero {
    position: relative;
    text-align: center;
    display: flex;
    width: 100%;

    &:before {
        content: "";
        background-color: var(--hero-background-color);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__background {
        // todo: this must be based on the theme
        flex: 1;
        position: relative;
        margin-inline: calc(-1 * var(--column-padding-normal));
        background-color: var(--hero-background-color);

        figure {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__content {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        text-align: left;
        bottom: var(--layout-padding-bottom);
        @screen md {
            bottom: 20px;
        }
        font: var(--hero-title-font);
        font-size: 3.2rem;
        color: var(--hero-title-color);
        text-shadow: 1px 1px rgba(var(--color-black-rgb), 0.5);
    }

    .o-section__layout & {
        margin-top: calc(var(--layout-padding-top) * -1);
        margin-bottom: calc(var(--layout-padding-bottom) * -1);
    }

    // Aspect ratios
    &--aspect-16x9 {
        @include aspect(16, 9);
    }
    &--aspect-10x3 {
        @include aspect(10, 3);
    }

    &--aspect-4x3 {
        @include aspect(4, 3);
    }
    &--aspect-4x1 {
        @include aspect(4, 1);
    }
    &--aspect-2-75x1 {
        @include aspect(2.75, 1);
    }
}
