.c-card {
    background: var(--card-background-color);
    color: var(--card-text-color);
    border-radius: var(--card-border-radius);
    padding: var(--card-padding);
    --link-color: var(--card-link-color);
    --link-hover-color: var(--card-link-color);
    text-align: var(--card-text-align, left);

    &__title {
        font: var(--copy-callout-font);
        margin-bottom: var(--padding-normal);
    }
}
