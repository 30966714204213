.o-block {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: var(--background-color);
    padding: var(--block-padding);
    flex: 1;
    align-items: flex-start;

    &--center, &--middle {
        align-items: center;
        text-align: center;
    }

    &--right {
        align-items: flex-end;
        text-align: right;
    }

    &--stretch {
        align-items: stretch;        
    }

    &--sm {
        --block-padding: 1rem;
    }
    // override tailwindclass set by umbraco on mobile
    @screen sm-only {
        &.mt-2xl {
            margin-top: 3em;
        }

        &.mb-2xl {
            margin-bottom: 3em;
        }
    }
}
