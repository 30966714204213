.c-homepage-hero {
    --height: min(700px, 100vh);
    position: relative;
    display: flex;
    min-height: calc(var(--height) - var(--main-nav-height));
    width: 100%;
    --title-color: var(--color-white);
    --text-color: var(--color-white);
    @screen md {
        --height: min(850px, 80vh);
    }

    padding: 0px 20px;

    @screen lg {
        padding: 0;
    }

    &__title {
        grid-row: 1;
        grid-column: 1 / -1;
        text-align: center;
        z-index: 1;

        .c-btn {
            display: inline-block;

            @screen md {
                display: none;
            }
        }
    }

    h1 {
        font-size: var(--font-size-h1);
        margin: 25px 0;
    }

    &__background {
        position: absolute;
        inset: 0;
        background: linear-gradient(
            270deg,
            rgba(190, 34, 108, 1) 0%,
            rgba(189, 35, 108, 1) 19%,
            rgba(11, 121, 184, 1) 72%,
            rgba(12, 120, 183, 1) 86%,
            rgba(0, 126, 188, 1) 100%
        );
        z-index: 0;
    }

    &__content {
        --mockups-width: 100%;
        --mockups-height: 500px;
        z-index: 1;
        display: grid;
        grid-template-columns: 1fr var(--mockups-width) 1fr;
        grid-template-rows: auto var(--mockups-height) auto;

        @screen md {
            grid-template-rows: auto var(--mockups-height);
            --mockups-width: 300px;
            --mockups-height: 400px;
        }
        @screen lg {
            --mockups-width: 500px;
            --mockups-height: 540px;
        }
        align-items: center;
        justify-items: center;
    }

    &__body {
        position: relative;
        text-align: center;
        max-width: 500px;
        display: none;

        @screen md {
            display: block;
        }
        &--left {
            grid-column: 1;
            grid-row: 2;
        }

        &--right {
            grid-column: 3;
            grid-row: 2;
        }
    }

    &__mockup-container {
        position: absolute;
        inset: 0;
        pointer-events: none;
        overflow: hidden;
    }
    &__mockup {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate3d(var(--translateX), var(--translateY), 0);
        --offsetX: 0px;
        --offsetY: 0px;
        --translateX: calc(-50% + var(--offsetX));
        --translateY: var(--offsetY);
        transition-property: transform, width;
        will-change: transform, width;
        transition-duration: 250ms;
        transform-origin: center bottom;

        &--left {
            --offsetX: -100px;
            --offsetY: -50px;
            width: 500px;

            @screen md {
                width: 500px;
                --offsetX: -70px;
            }
            @screen lg {
                width: 700px;
                --offsetY: -20px;
                --offsetX: -115px;
            }
        }

        &--right {
            --offsetX: 70px;
            --offsetY: 4vh;
            width: 300px;
            @screen md {
                width: 280px;
                --offsetX: 95px;
                --offsetY: 50px;
            }
            @screen lg {
                width: 400px;
                --offsetX: 150px;
                --offsetY: 50px;
            }
        }
    }

    .o-copy__title {
        font-size: 2.7rem;
        margin-bottom: 4px;
    }

    .o-copy__call-out {
        font-size: 1.4rem;
        margin: 0;
        margin-bottom: 20px;
    }

    .c-btn {
        font-size: var(--font-size-footer);
        font-weight: var(--font-weight-normal);
        padding: 5px 20px;
    }

    blockquote {
        quotes: "“" "”" "‘" "’";
        font-size: var(--font-size-footer);
        font-weight: var(--font-weight-normal);
        font-family: var(--font-family-small);
        line-height: normal;
        &::before {
            content: open-quote;
        }
        &::after {
            content: close-quote;
        }
        margin-bottom: 20px;
    }
}
