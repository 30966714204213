.o-theme--dark {
    --main-theme-color: var(--color-black);
    --secondary-theme-color: var(--color-white);

    --contrasting-background-color: var(--color-white);
    // --link-color: var(--color-white);
    --accent-color: var(--color-white);
    --title-color: var(--color-white);
    --hero-title-color: var(--color-white);
    --button-color: var(--color-white);
    --text-color: var(--color-white);
    --copy-text-color: var(--color-white);
}

.o-theme--light {
    // default
    --main-theme-color: var(--color-white);
    --secondary-theme-color: var(--color-black);
    --contrasting-background-color: #f2f2f2;
    // --link-color: var(--color-black);
    --accent-color: var(--color-black);
    --title-color: var(--color-black);
    --hero-title-color: var(--color-black);
    --button-color: var(--color-black);
    --text-color: var(--color-black);
    --copy-text-color: var(--color-black);
}

.o-theme--kwieb {
    --main-theme-color: var(--color-pink-dark);
    --secondary-theme-color: var(--color-white);

    // --link-color: var(--color-pink);
    --accent-color: var(--color-pink);
    --title-color: var(--color-pink);
    --hero-title-color: var(--color-pink);
    --button-color: var(--color-pink);
}

.o-theme--gnap {
    --main-theme-color: var(--color-blue);
    --secondary-theme-color: var(--color-white);

    --contrasting-background-color: var(--color-white);
    // --link-color: var(--color-blue);
    --title-color: var(--color-blue);
    --hero-title-color: var(--color-blue);
}

.o-theme--app {
    // TODO: set the o_copy fonts
}
