@font-face {
  font-family: 'fiep';
  src: url('../font/fiep.woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fiep';
    src: url('../font/fiep.svg?7574921#fiep') format('svg');
  }
}
*/
[class^="fiep-icon-"]:before, [class*=" fiep-icon-"]:before {
  font-family: "fiep";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.fiep-icon-down-open-big:before { content: '\e764'; } /* '' */
.fiep-icon-up-open-big:before { content: '\e767'; } /* '' */
.fiep-icon-chat:before { content: '\e800'; } /* '' */
.fiep-icon-check:before { content: '\e801'; } /* '' */
.fiep-icon-attach:before { content: '\e802'; } /* '' */
.fiep-icon-info-circled-1:before { content: '\e803'; } /* '' */
.fiep-icon-cancel:before { content: '\e804'; } /* '' */
.fiep-icon-attention:before { content: '\e805'; } /* '' */
.fiep-icon-filter:before { content: '\e806'; } /* '' */
.fiep-icon-globe:before { content: '\e807'; } /* '' */
.fiep-icon-illness-off-icon:before { content: '\e809'; } /* '' */
.fiep-icon-dot-3:before { content: '\e81d'; } /* '' */
.fiep-icon-topic:before { content: '\e81e'; } /* '' */
.fiep-icon-illness-old-on-icon:before { content: '\e826'; } /* '' */
.fiep-icon-coronacomplaints-on-icon:before { content: '\e82b'; } /* '' */
.fiep-icon-coronatest-on-icon:before { content: '\e82c'; } /* '' */
.fiep-icon-coronaquarantine-on-icon:before { content: '\e82d'; } /* '' */
.fiep-icon-wedding-on-icon:before { content: '\e900'; } /* '' */
.fiep-icon-wedding-off-icon:before { content: '\e901'; } /* '' */
.fiep-icon-specialist-on-icon:before { content: '\e902'; } /* '' */
.fiep-icon-specialist-off-icon:before { content: '\e903'; } /* '' */
.fiep-icon-dentist-on-icon:before { content: '\e904'; } /* '' */
.fiep-icon-dentist-off-icon:before { content: '\e905'; } /* '' */
.fiep-icon-gp-on-icon:before { content: '\e906'; } /* '' */
.fiep-icon-gp-off-icon:before { content: '\e907'; } /* '' */
.fiep-icon-illness-on-icon:before { content: '\e908'; } /* '' */
.fiep-icon-remark-icon:before { content: '\e90e'; } /* '' */
.fiep-icon-funeral-on-icon:before { content: '\e910'; } /* '' */
.fiep-icon-funeral-off-icon:before { content: '\e911'; } /* '' */
.fiep-icon-religious-on-icon:before { content: '\e912'; } /* '' */
.fiep-icon-religious-off-icon:before { content: '\e913'; } /* '' */
.fiep-icon-right:before { content: '\e950'; } /* '' */
.fiep-icon-pencil:before { content: '\e951'; } /* '' */
.fiep-icon-right-open-big:before { content: '\e952'; } /* '' */
.fiep-icon-heart-empty:before { content: '\e953'; } /* '' */
.fiep-icon-heart:before { content: '\e954'; } /* '' */
.fiep-icon-forward:before { content: '\e955'; } /* '' */
.fiep-icon-menu:before { content: '\f0c9'; } /* '' */
.fiep-icon-chat-empty:before { content: '\f0e6'; } /* '' */
.fiep-icon-info:before { content: '\f129'; } /* '' */
.fiep-icon-file-doc-inv:before { content: '\f15b'; } /* '' */
.fiep-icon-paper-plane:before { content: '\f1d8'; } /* '' */
