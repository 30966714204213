.c-breadcrumb {
    --font-size: 1.4rem;
    background-color: var(--color-grey-light);
    height: var(--breadcrumb-height);
    overflow: hidden;

    &__container {
        max-width: var(--layout-width);
        margin: 0 auto;
        display: flex;
        justify-items: center;
        font-size: var(--font-size);
        text-transform: lowercase;
        padding-inline: var(--column-padding-normal);
        height: 100%;
        align-items: center;
    }

    &__item {
        line-height: var(--breadcrumb-height);
        color: var(--color-grey-darker);
        white-space: nowrap;
        height: 100%;
        & + .c-breadcrumb__item {
            &:before {
                content: ">";
                position: relative;
                margin: 0 .5ch;
                color: var(--color-grey-darker);
            }

            a {
                margin-left: -4px; // we have a whitespace before the link
            }
        }

        &--active {
            color: var(--title-color);
        }

        a {
            display: inline-block;
            color: unset;
            height: 100%;
        }
    }
}
