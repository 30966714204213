.o-frame {
    --w: 16;
    --h: 9;
    display: block;
    position: relative;
    padding-bottom: calc(var(--h) / var(--w) * 100%);

    > * {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    > img,
    > video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &--4x3 {
        --w: 4;
        --h: 3;
    }
    &--5x4 {
        --w: 5;
        --h: 4;
    }
}