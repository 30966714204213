.c-news-list-block {
    --padding: 1rem;
    display: flex;
    gap: var(--padding);

    a {
        overflow: hidden;
    }

    figure {
        aspect-ratio: 16/9;
        flex-basis: 50px;
        flex-shrink: 0;
        flex-grow: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

footer {
    .c-news-list-block {
        flex-direction: column;

        &__item {
            a {
                display: flex;
                gap: var(--padding);
                align-items: flex-start;
                line-height: var(--line-height-tight);
            }

            figure {
                width: 50px;
            }

            &-title {
                font-size: inherit;
                font-weight: var(--font-weight-normal);
            }

            &-body {
                display: none;
            }
        }
    }
}

main {
    .c-news-list-block {
        flex-direction: row;
        flex-wrap: wrap;
        --padding: 2.5rem;

        &__item {
            --link-color: var(--text-color);
            color: var(--text-color);
            background-color: var(--color-grey-light);
            padding-bottom: var(--padding);
            flex: 1 250px;

            a {
                overflow: hidden;
                &:hover {
                    color: var(--text-color);

                    img {
                        transform: scale(1.16);
                    }
                }
            }

            figure {
                aspect-ratio: 16/9;
                width: 100%;
                height: auto;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: transform 1s ease-in-out;
                }
            }

            &-title {
                padding-inline: var(--padding);
                color: var(--accent-color);
                margin-top: 1em;
                margin-bottom: .5em;
            }
            &-body {
                display: block;
                color: inherit;
                padding-inline: var(--padding);
            }
        }
    }
}
