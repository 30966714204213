.c-btn {
    --button-opacity: 1;
    --button-hover-opacity: 1;
    --button-color: var(--accent-color);
    --button-text-color: var(--color-white);
    --button-border-color: var(--button-color);
    --button-color-hover: var(--button-color);
    --button-text-color-hover: var(--button-text-color);
    --link-color: var(--button-text-color);
    --link-hover-color: var(--button-text-color-hover);
    position: relative;
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    max-width: 100%;
    border-radius: 0.25rem;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-property: background-color, opacity;
    font: var(--text-font);
    border-radius: var(--border-radius);
    color: var(--button-text-color);
    background-color: var(--button-color);
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
    opacity: var(--button-opacity);
    transform: translateY(0px);
    text-decoration: none;
    outline: none;

    // we can also have a span with a link inside
    a {
        text-decoration: none;
        
        // this is for our underlines hovers
        &::before {
            display: none;
        }
    }

    i {
        transition: transform 0.1s;
    }

    &:hover,
    &:active {
        --button-opacity: var(--button-hover-opacity);
        background-color: var(--button-color-hover);
        color: var(--button-text-color-hover);
        text-decoration: none;
    }

    &:active {
        transform: translateY(1px);
    }

    &:disabled {
        --button-opacity: 0.5;
        background-color: var(--button-color);
        cursor: not-allowed;
    }

    &--primary {
        --button-color: var(--color-blue);
        --button-color-hover: var(--color-blue-darker);
    }

    &--secondary {
        --button-color: var(--color-orange);
        --button-color-hover: var(--color-orange-darker);
    }

    &--cta,
    &--cta-blue {
        --button-color: var(--color-blue);
        --button-text-color: var(--color-white);
        --button-color-hover: var(--color-blue-darker);
    }

    &--cta-white {
        --button-color: var(--color-white);
        --button-text-color: var(--color-blue);
        --button-text-color-hover: var(--color-blue-darkest);
    }

    &--cta-pink {
        --button-color: var(--color-pink);
        --button-text-color: var(--color-white);
        --button-color-hover: var(--color-pink-dark);
    }

    &--cta-black {
        --button-color: #333;
        --button-text-color: var(--color-white);
        --button-color-hover: var(--color-true-black);
    }

    &--cta-orange {
        --button-text-color: var(--color-white);
        --button-color: var(--color-orange);
        --button-color-hover: var(--color-orange-darker);
    }

    &--arrow, &--monster-white, &--monster-blue {
        width: auto;
        text-align: left;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;

        &:after {
            position: relative;
            content: "\e900";
            font-family: icomoon;
            transition: transform 0.1s;
        }

        &:hover:after {
            transform: translateX(2px);
        }

        &:disabled:after {
            transform: translateX(0);
        }

        &--large {
            min-width: 350px;
        }
    }

    &--monster-white, &--monster-blue {
        overflow: visible;
        padding-left: 5em;
        margin-block: 1em;
        max-width: 100%;

        &:before {
            position: absolute;
            content: "";
            left: 2rem;
            width: 100px;
            height: 4.5em;
            background-image: url(@wwwroot/images/monsters/Button.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &--monster-white {
        --button-color: var(--color-white);
        --button-text-color: var(--color-pink);
        --button-text-color-hover: var(--color-pink-dark);
    }

    &--monster-blue {
        --button-color: var(--color-blue);
        --button-text-color: var(--color-white);
        --button-text-color-hover: var(--color-white);
    }

    svg {
        position: relative;
        float: right;
        height: 1em;
        width: auto;

        path {
            stroke: var(--knock-out-color);
        }
    }

    &--link {
        --button-color: var(--color-transparent);
        --button-text-color: var(--link-color);
    }

    &--light {
        --button-color: var(--color-white);
        --knock-out-color: var(--color-blue);
    }

    &--read-more {
        --button-color: var(--color-transparent);
        --button-text-color: var(--color-black);
        // font-weight: var(--font-weight-normal);
        // font-size: 1.3rem;
        // font-style: italic;
        border-style: solid;
        border-width: 1px;
        border-color: var(--button-text-color);
        border-radius: 999px;
        box-shadow: 0px 1px 35px 15px rgba(var(--color-black-rgb), 0.05);

    }
    &--read-more-white {
        --button-color: var(--color-transparent);
        --button-text-color: var(--color-white);
        // font-weight: var(--font-weight-normal);
        // font-size: 1.3rem;
        // font-style: italic;
        border-style: solid;
        border-width: 1px;
        border-color: var(--button-text-color);
        border-radius: 999px;
        box-shadow: 0px 1px 35px 15px rgba(var(--color-black-rgb), 0.05);
    }
}

span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.7);
    pointer-events: none;
}
@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

.cta-blue {
    @extend .c-btn;
    @extend .c-btn--cta-blue;
    @extend .c-btn--arrow;    
}

.cta-white {
    @extend .c-btn;
    @extend .c-btn--cta-white;
    @extend .c-btn--arrow;    
}

.cta-orange {
    @extend .c-btn;
    @extend .c-btn--cta-orange;
    @extend .c-btn--arrow;
}