.u-noscroll {
    overflow: hidden;
}

.u-breakout-of-layout {
    @screen sm {
        width: calc(var(--layout-width) + 60px);
        margin-left: 50%;
        transform: translateX(-50%);
    }

    &--full {
        @screen sm {
            width: 100vw;
        }
    }
}

.u-inset-shadow {
    @screen sm {
        > * {
            z-index: 2;
        }

        > img {
            z-index: 0;
        }

        &:after {
            --start: rgba(var(--color-black-rgb), 0.7);
            --end: rgba(var(--color-black-rgb), 0.26);
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(var(--start), var(--end));
        }
    }
}

.u-invert {
    filter: invert(100%);
}

.u-no-interaction {
    user-select: none;
    pointer-events: none;
}
.u-google-disclaimer {
    display: block;
    text-align: center;
    margin-top: 2rem;
}
// text sizing
.u-text-large {
    font-size: var(--font-size-text-large);
}

.u-text-normal {
    font-size: var(--font-size-text);
}
.u-text-small {
    font-size: var(--font-size-small);
}
@screen sm {
    .u-content-columns-1 {
        column-count: 1;
    }
    .u-content-columns-2 {
        column-count: 2;
    }
    .u-content-columns-3 {
        column-count: 3;
    }
    .u-content-columns-4 {
        column-count: 4;
    }
    .u-content-columns-5 {
        column-count: 5;
    }
}

// for our Aplinejs x-cloak to work correctly
[x-cloak] {
    display: none !important;
}

// for our lazyloading images
img:not([src]):not([srcset]) {
    visibility: hidden;
}

img.lazy {
    opacity: 0;
    transition: opacity 0.2s;

    &.loaded {
        opacity: 1;
    }
}

.u-separate-sections {
    & > .o-section {
        border-bottom: 1px solid var(--color-white);
    }
}

.u-button-stack {
    span {
        margin-bottom: 10px;
        margin-right: 10px;
    }
}

.u-hidden {
    display: none;
}
