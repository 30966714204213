@mixin block($number){
    &--#{$number} {
        max-width: calc(#{$number} * var(--item-width));
    }
}
.c-employees-block {
    --item-width: calc(var(--avatar-size) + 20px);
    --avatar-size: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: auto;

    @include block(1);
    @include block(2);
    @include block(3);
    @include block(4);
    @include block(5);
    @include block(6);
   

    &--small {
        --avatar-size: 75px;
    }
    &--large {
        --avatar-size: 150px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__avatar {
        border-radius: 50%;
        overflow: hidden;
        aspect-ratio: 1;
        width: var(--avatar-size);
        border: 1px solid #ccc;
        background-color: #ececec;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__name {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-info);
        margin-top: 0.5rem;
        white-space: nowrap;
    }
}
