.c-phone-graphic {
    --phone-border-radius: 36px;
    --phone-top-notch-height: 44px;

    position: relative;
    transform: scale(1);
    width: calc(375px + 24px);
    height: calc(812px + 24px);
   
    padding: 8px;
    border-radius: var(--phone-border-radius);
    overflow: hidden;
    background: #fff;
    user-select: none;
    font-size: 16px;
    padding: 12px;
    flex-shrink: 0;
    flex-grow: 0;

    &--scalable{
        aspect-ratio: 399 / 836;
        height: auto;
        width: 100%;
    }

    &__mask {
        position: absolute;
        inset: 12px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            height: var(--phone-top-notch-height);
            width: 100%;
            background-color: var(--color-white);
            background-repeat: no-repeat;
            mask-image: url("../icons/phone-notch-top.svg");
            mask-mode: luminance;
            mask-repeat: no-repeat;
        }
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 38px;
            bottom: 0;
            background-color: var(--color-black);
            background-repeat: no-repeat;
            mask-image: url("../icons/phone-notch-bottom.svg");
            mask-repeat: no-repeat;
        }
    }

    &__screen {
        position: relative;
        height: 100%;
        border-radius: var(--phone-border-radius);
        overflow: hidden;
        background: var(--color-blue);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
