// 01 settings
@import '01-settings/variables';

// 02 Tools

// 03 Generic
@tailwind base;
@import '03-generic/fonts';

// 04 Elements
@import '04-elements/layout';
@import '04-elements/headings';
@import '04-elements/footer';
@import '04-elements/header';
@import '04-elements/form';
@import '04-elements/table';
@import '04-elements/links';

// 05 Objects
@import '05-objects/block';
@import '05-objects/container';
@import '05-objects/sections';
@import '05-objects/copy';
@import '05-objects/depths';
@import '05-objects/stack';
@import '05-objects/frame';
@import '05-objects/user-content';
@import '05-objects/themes';
@import '05-objects/flavors';

// 06 Components
@tailwind components;
@import '06-components/buttons';
@import '06-components/card';
@import '06-components/nav';
@import '06-components/quick-nav';
@import '06-components/site-menu';
@import '06-components/hero';
@import '06-components/hero-homepage';
@import '06-components/language-selector';
@import '06-components/phone-graphic';
@import '06-components/breadcrumb';
@import '06-components/list';
@import '06-components/tags';
@import '06-components/column-block';
@import '06-components/slider';
@import '06-components/stars';
@import '06-components/employee-block';
@import '06-components/clients-block';
@import '06-components/reviews';
@import '06-components/hero-block';
@import '06-components/news-list-block';
@import '06-components/image-block';
@import '06-components/page-overlay';

// 99 Utils 
@tailwind utilities;
@import '99-utils/utilities';
@import '99-utils/umbraco-overrides';
@import '99-utils/effects';
@import '99-utils/pages';




@tailwind screens;