footer {
    --font-size-body: var(--font-size-footer);
    --footer-background: linear-gradient(to bottom, #ededed 0%, #f3f3f3 100%);
    --text-color: var(--color-black);
    --link-color: var(--color-black);
    --block-padding: 0;
    --layout-padding-top: 5rem;
    --layout-padding-bottom: 5rem;
    --form-padding: 0.5rem;

    // reset any paragraphs here
    --font-size-text-large: var(--font-size-footer);

    --form-background-color: var(--color-grey);
    --form-border-color: var(--color-grey-light);
    --form-color: var(--color-blue);

    font: var(--text-font);
    font-size: var(--font-size-body);
    background: var(--footer-background);

    .form-checkbox,
    .form-radio {
        &:focus {
            --tw-ring-offset-color: black;
        }
    }
    .umbraco-forms-form {
        padding: 0;

        .umbraco-forms-fieldset {
            padding: 0;
            margin: 0;

            + .umbraco-forms-fieldset {
                margin-top: 1rem;
            }
        }

        .umbraco-forms-label {
            font-weight: var(--font-weight-normal);
            font-size: var(--font-size-text);
        }

        .dataconsent label {
            font-size: 1.3rem;
        }
    }
    .footer-logo {
        display: block;
        width: 106px;
        height: 25px;
        max-height: 30px;
        align-self: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../images/ziber-logo.png");
        margin-bottom: 2rem;
        span {
            display: none;
        }
    }

    .footer-grid {
        display: grid;
        grid-auto-flow: row;
        grid-gap: 1em;
        @screen sm {
            grid-template-columns: repeat(5, 1fr);
        }

        .o-user-content {
            @screen sm {
                h3 {
                    margin-top: 1em;
                    margin-bottom: 1em;
    
                    ~ h3 {
                        margin-bottom: 0.25em;
                    }
                }

            }
        }
        
        // This is the first column with the logos
       > div:first-of-type .o-user-content {
            p {
                overflow: hidden;
                a.image-link {
                    float: left;
                    margin-right: 1rem;
                }
            }
        }

        // This is the last column with the news letter
        > div:last-of-type .o-user-content {
            .c-btn{
                --button-color: var(--color-grey-dark);
                font-size: var(--font-size-small);
            }

            .umbraco-forms-ziber{
                --font-size-text: var(--font-size-footer);

                .umbraco-forms-container{
                    row-gap: 1rem;
                }
            }
        }
    }

    .footer-links {
        display: grid;
        grid-template-columns: 1fr auto;
        margin-top: 4rem;

        ul {
            display: flex;
            flex-direction: row;
            gap: 1em;
        }
    }
}
