.c-top-nav {
    --background-color: var(--color-grey-light);
    --accent-color: var(--color-blue);
    --block-padding: 0;
    font: var(--navigation-font);
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-grey-light);
    height: var(--main-nav-height);
    width: 100%;

    .c-btn {
        padding-block: 0.7rem; // for the same size as our language selector
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-text);
    }

    &__item {
        position: relative;
        display: none;

        @screen md {
            display: flex;
        }

        // prevents span to trigger mouse-events
        span.c-top-nav__item-link {
            pointer-events: none;
        }
    }

    &__main {
        display: grid;
        grid-gap: 2rem;
        height: var(--main-nav-height);
        grid-template-columns: 1fr auto;
        grid-auto-flow: column;
        justify-content: space-between;
        align-content: stretch;
        padding-inline: var(--column-padding-normal);
        @screen md {
            justify-content: center;
            max-width: var(--layout-width);
            margin: 0 auto;
            // give the first real item (not the logo) the most room, to allow for a better mouseover/mouseout experience
            grid-template-columns:  1fr repeat(5, auto);
        }
        align-items: center;

        .c-top-nav__item {
            --link-color: var(--text-color);
            position: relative;
            height: 100%;
            align-items: center;
            cursor: pointer;
            word-wrap: anywhere;
            justify-content: flex-end;

            &:last-child {
                padding-right: 0;
            }

            // logo
            &-logo {
                height: auto;
                display: block;
            }
            &-payoff {
                grid-column: 1 / span 6; // 6 should be a reasonable number, we can't use -1 because of auto-flow
                margin: 0;
            }

            &--open,
            &:hover,
            &:focus-within {
                --link-color: var(--accent-color);
            }

            &-hamburger {
                display: block;
                font-size: 2rem;
                cursor: pointer;
                height: auto;
                padding-inline-end: var(--column-padding-normal);

                @screen md {
                    display: none;
                }
            }
        }
    }

    &__item-link{
        color: var(--link-color);
    }

    &__logo {
        display: block;
        min-width: 130px;
        max-width: 200px;
        height: var(--main-nav-height);
        max-height: 30px;
        align-self: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../images/ziber-logo.png");
    }

    // general styling for user content
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0.25em;
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-info);
    }

    h2 {
        font-size: var(--font-size-text);
    }
    h6 {
        color: var(--title-color);
    }
    p + p {
        margin-top: 1em;
    }

    p + h6 {
        margin-top: 1em;
    }
}
