.swiper-block {
    .swiper-slide {
        display: flex;
        justify-content: center;

        img {
            width: 300px;
            height: 270px;
            border-radius: 20px;
        }

        .title {
            &:hover {
                background-color: var(--slider-hover-bgcolor) !important;
                color: var(--slider-hover-color) !important;
            }
            position: absolute;
            bottom: 15px;
            left: 15px;
            right: 15px;
            border: 2px solid #fff;
            color: #fff;
            line-height: 45px;
            border-radius: 20px;
            font-size: 1.7rem;
            text-align: center;
            font-weight: 600;
        }
    }
}