.c-reviews {
    display: grid;
    grid-auto-flow: row;
    gap: 20px;

    @screen sm {
        grid-auto-flow: column;
        grid-template-columns: repeat(var(--nrofreviews), minmax(200px, 1fr));
    }

    .c-review {
        display: flex;
        flex-direction: column;
        position: relative;        

        &__text {
            margin-bottom: 100px;
        }

        &__store {
            font-style: italic;
        }

        &__name {
            font-style: italic;
            margin-bottom: 10px;
        }


        &__info {
            position: absolute;
            bottom: 3rem;
        }
    }

    .c-stars {
        font-size: 3rem;
    }
}
