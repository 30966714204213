.c-page-overlay {
    position: fixed;
    inset: 0;
    z-index: 2;
    opacity: 0;
    background-color: rgb(0, 0, 0);
    transition-property: opacity;
    transition: 100ms;
    will-change: opacity;
    pointer-events: none;

    &--visible {
        pointer-events: all;
        opacity: 0.663295;
    }
}
