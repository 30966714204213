.c-styled-list {
    list-style-type: disc;
    text-align: left;
    padding-left: 1em;

    li {
        position: relative;
    }

    &--disc {
        list-style-type: disc;
    }

    &--circle {
        list-style-type: circle;
    }

    &--checks {
        list-style-type: none;
        padding-left: 0;

        li {
            padding-left: 1.25em;

            &:before {
                position: absolute;
                left: 0;
                content: "\2713";
                font-family: Times; // make sure 🗸 appears correctly
            }
        }
    }
}
