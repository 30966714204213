.c-image-block {
    img {
        max-width: auto;
    }
    &--mobile-max {
        @screen sm-only {
            img {
                max-width: 100% !important;
            }
        }
    }
}
