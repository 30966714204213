// override default tailwindcss form rules
.form-input,
.form-textarea,
.form-select,
.form-multiselect {
    display: block;
    width: 100%;
    font-family: var(--form-font-family);
    font-size: var(--font-size-text);
    margin-top: 0.25rem;
    padding: var(--form-padding);
    color: var(--form-color);
    background-color: var(--form-background-color);
    border-radius: var(--border-radius);
    border: 1px solid var(--form-border-color);
    box-shadow: none !important; // tailwind uses box-shadow for a ring by default
    line-height: normal;
    &:focus {
        outline: none;
        border-color: var(
            --form-border-color
        ); // override default tailwindcss form rules
        border-bottom-color: var(--form-border-color-active);
        border-end-end-radius: 0;
        border-end-start-radius: 0;
    }
}

.form-checkbox,
.form-radio {
    width: 1.4rem;
    height: 1.4rem;
    color: var(--accent-color);

    &:focus {
        --tw-ring-color: var(--accent-color);
    }
}

.form-input,
.form-textarea {
    &::placeholder {
        font-family: var(--form-placeholder-font-family);
        opacity: .6;
        color: var(--form-color);
    }
}

.umbraco-forms-ziber {
    .umbraco-forms-container {
        display: grid;
        grid-auto-rows: auto;
        row-gap: 2rem;
        max-width: 60ch;
    }

    .umbraco-forms-field {
        label.umbraco-forms-label {
            font-family: var(--form-font-family);
            font-weight: var(--font-weight-light);
            font-size: var(--font-size-info);
            opacity: 0.6;
            display: inline-flex;
            align-items: center;
        }

        label .form-checkbox ~ span,
        label .form-radio ~ span {
            margin-left: 0.5rem;
        }

        .field-validation-error {
            display: block;
            color: var(--color-danger);
            font-size: 1.1rem;
            font-style: italic;
            padding-left: 5px;
        }
    }

    .umbraco-forms-navigation {
        margin-top: 1rem; //same as the grid row gap
    }

    .umbraco-forms-caption {
        font: var(--text-font);
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-text) !important;
        line-height: auto !important;
    }
}
.grecaptcha-badge {
    visibility: hidden;
    opacity: 0;
}
