.o-section {
    position: relative;
    z-index: 1;
    color: var(--text-color);

    &--full-height {
        min-height: var(--full-height);
    }

    &--with-background {
        .o-block {
            background: none;
        }
    }

    &__container {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
    }

    &__container-layout {
        width: 100%;
        max-width: var(--layout-width);
        margin: 0 var(--column-padding-normal);
        position: relative;
    }

    &__layout {
        padding: var(--layout-padding-top) 0 var(--layout-padding-bottom) 0;

        > .o-block {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__masked {
        overflow: var(--section-background-overflow);
    }

    &__background-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: var(--section-background-overflow);

        &--overlay {
            &::after {
                content: "";
                position: absolute;
                inset: 0;
                z-index: 1;
                background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0.45) 0%,
                    rgba(0, 0, 0, 0) 100%
                );
            }
        }
    }

    &__background {
        position: relative;
        height: 100%;
        max-height: none;
        width: 100%;
        top: 0;
        left: 0;
        background: var(--background-color);
        //overflow: hidden;

        &-video {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 1;

            iframe {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }

        figure {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 0;

            img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__monster {
        display: none;
        @screen lg {
            display: block;
        }
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        overflow: visible;
        width: 100%;
        height: 100%;

        > div {
            position: absolute;
            overflow: visible;
            z-index: 1000;
        }
    }
}
